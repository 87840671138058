<template>
    <div class="authorization-back"></div>
</template>

<script>
    export default {
       name: 'authorization-back',
        data () {
           return {
               code: '',
               openId: '',
               token: ''
           }
        },
        created () {
            this.code = this.$route.query.code || ''
            this.$nextTick(() => {
                if (this.code) {
                    this.getAccessToken()
                }
            })
        },
        methods: {
            getAccessToken () {
               this.$http({
                   url: this.$http.adornUrl(this.$api.QUALITY_DOCTOR.WECHAT_TOKEN),
                   method: 'GET',
                   params: this.$http.adornParams({
                       code: this.code
                   })
               }).then(({data}) => {
                   if (data && data.code === 0) {
                       this.openId = data.openid || ''
                       this.token = data.token || ''
                       if (this.token) {
                           this.$cookie.set('marketToken', data.token)
                           this.$router.push({name: 'service-module'})
                       } else {
                           this.$router.push({
                               name: 'h5-login',
                               params: {
                                   openid: this.openId
                               }
                           })
                       }
                   } else {
                       this.$message.error(data.msg)
                   }
               })
           }
        }
    }
</script>

<style scoped>

</style>